import React, { Component } from 'react';

class About extends Component {
    render() {

        if(this.props.data){
            var profilepic= "images/" + this.props.data.image;
            var bio = this.props.data.bio;
            var resumeDownload = this.props.data.resumedownload;
        }

        return (
            <section id="about">
                <div className="row">
                    <div className="three columns side-col">
                        <img className="profile-pic"  src={profilepic} alt="Will Powell Profile Pic" />
                    </div>
                    <div className="eight columns main-col">
                        <h2>About Me</h2>
                        <p>{bio}</p>
                        <div className="row">
                            <div className="columns download">
                                <p>
                                    <a href={resumeDownload} target="_blank" rel="noopener noreferrer" className="button"><i className="fa fa-download"></i>View my CV</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        );
    }
}

export default About;
