import React, { Component } from 'react';
import emailjs from "emailjs-com";

class Contact extends Component {
  render() {
    if(this.props.data){
      var sendEmail = function sendEmail(e) {
          e.preventDefault();

          emailjs.init('TRVRQ_2BYtHzadgCR');

          //get id's from https://dashboard.emailjs.com/admin and https://dashboard.emailjs.com/admin/templates and https://dashboard.emailjs.com/admin/integration
          emailjs.sendForm('service_jy86hz5', 'template_37dxgc8', e.target, 'TRVRQ_2BYtHzadgCR')
              .then((result) => {
                  console.log(result.text);
              }, (error) => {
                  console.log(error.text);
              });
          e.target.reset();
      }
    }

    return (
      <section id="contact">
         <div className="row section-head">
            <div className="three columns email">
               <h1></h1>
            </div>
            <h69>Get in Touch.</h69>
         </div>

         <div className="row">
            <div className="twelve columns">

               <form onSubmit={sendEmail}>
					<fieldset>

                  <div>
						   <label htmlFor="contactName">Name <span className="required">*</span></label>
						   <input type="text" defaultValue="" size="35" id="contactName" name="name" onChange={this.handleChange}/>
                  </div>

                  <div>
						   <label htmlFor="contactEmail">Email <span className="required">*</span></label>
						   <input type="text" defaultValue="" size="35" id="contactEmail" name="email" onChange={this.handleChange}/>
                  </div>

                  <div>
						   <label htmlFor="contactSubject">Subject</label>
						   <input type="text" defaultValue="" size="35" id="contactSubject" name="subject" onChange={this.handleChange}/>
                  </div>

                  <div>
                     <label htmlFor="contactMessage">Message <span className="required">*</span></label>
                     <textarea cols="50" rows="15" id="contactMessage" name="message"></textarea>
                  </div>

                  <div>
                      <button className="submit">Submit</button>
                        <span id="image-loader">
                            <img alt="" src="images/loader.gif" />
                        </span>
                  </div>
					</fieldset>
				   </form>

           <div id="message-warning"> Error boy</div>
				   <div id="message-success">
                    <i className="fa fa-check"></i>Your message was sent, thank you!<br />
				   </div>
           </div>
      </div>
   </section>
    );
  }
}

export default Contact;
